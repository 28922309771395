import React from 'react';
import { useSelector } from 'react-redux';
import { useGetAvailableAccountsQuery } from 'services/users';

import { LoadingScreen } from 'pages/loading/loading';

import { selectAccountAccess } from 'state/selectors/accounts/accountSelector';
import { selectAccountType } from 'state/slices/accountSlice';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountAccessLevel, AccountType } from 'types/account';
import { RouteSetup, RouteSetupFactory } from 'types/routing';
import { UserAccessLevel } from 'types/user';

import { AuthenticationGuard } from './authenticationGuard';
import Routes from './routes';
import AdminRouterSetup from './routes/admin';
import PartnerRouterSetup from './routes/partner';
import SuperAdminRouterSetup from './routes/superAdmin';
import SupportRouterSetup from './routes/support';
import SuspendedRouterSetup from './routes/suspended';

interface GetRoutesArgs {
  userLevel: UserAccessLevel;
  accountAccessLevel?: AccountAccessLevel;
  isSuspended?: boolean;
  hasAccounts?: boolean;
  accountType?: AccountType;
}

const routerSetupMap: Record<UserAccessLevel, RouteSetup | RouteSetupFactory> = {
  [UserAccessLevel.Admin]: AdminRouterSetup,
  [UserAccessLevel.SuperAdmin]: SuperAdminRouterSetup,
  [UserAccessLevel.Partner]: PartnerRouterSetup,
  [UserAccessLevel.Support]: SupportRouterSetup,
};

const getRoutes = ({ userLevel, isSuspended, accountAccessLevel, hasAccounts, accountType }: GetRoutesArgs): RouteSetup => {
  if (isSuspended) {
    return SuspendedRouterSetup;
  }

  const selectedSetup = routerSetupMap[userLevel];
  if (typeof selectedSetup === 'function') {
    return selectedSetup({
      accountAccessLevel,
      hasAccounts,
      accountType,
    });
  }

  return selectedSetup;
};

export const RoutesContainer = () => {
  const userLevel = useSelector(selectUserLevel);
  const accountType = useSelector(selectAccountType);
  const accountAccessLevel = useSelector(selectAccountAccess);
  const isSuspended = false;

  const isPartner = userLevel === UserAccessLevel.Partner;
  const { data: availableAccountsData } = useGetAvailableAccountsQuery({});

  const hasAccounts = isPartner ? Boolean(availableAccountsData?.data?.length) : undefined;

  if (!userLevel) {
    return <AuthenticationGuard component={LoadingScreen} />;
  }

  const routeSetup = getRoutes({ userLevel, isSuspended, accountAccessLevel, hasAccounts, accountType });

  return <Routes routeSetup={routeSetup} />;
};

export default RoutesContainer;
