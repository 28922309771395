import styled from '@emotion/styled';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Link as MuiLink,
  LinkProps as MuiLinkProps,
} from '@mui/material';
import React, { ElementType } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

import { KEYS } from './keys';

export const DrawerContent = styled.div(({ theme }) => ({
  padding: theme.spacing(2.6),
}));

export const LogoWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '55px',
  position: 'relative',
}));

export const ExpandedLogoImg = styled.img<{ open: boolean }>(({ open }) => ({
  position: 'absolute',
  opacity: open ? 1 : 0,
  transition: `opacity ${KEYS.LOGO_TRANSITION_EASING} ${KEYS.LOGO_TRANSITION_DURATION}`,
}));

export const CollapsedLogoImg = styled.img<{ open: boolean }>(({ open }) => ({
  position: 'absolute',
  opacity: !open ? 1 : 0,
  transition: `opacity ${KEYS.LOGO_TRANSITION_EASING} ${KEYS.LOGO_TRANSITION_DURATION}`,
}));

export const ListMenu = styled(List)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const ListMenuItem = styled(ListItem)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ListMenuSubheader = styled(ListSubheader)<{ open: boolean; first: boolean }>(({ open, first }) => ({
  fontSize: '10px',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  visibility: open ? 'visible' : 'hidden',
  marginTop: !first ? '18.5px' : 0,
  whiteSpace: 'nowrap',
}));

export const ListMenuItemButton = styled(ListItemButton)(({ theme }) => ({
  justifyContent: 'center',
  maxHeight: theme.spacing(4),
  borderRadius: '5px',
  transition: `background-color ${KEYS.MENU_ITEM_EASING} ${KEYS.MENU_ITEM_DURATION}, 
               color ${KEYS.MENU_ITEM_EASING} ${KEYS.MENU_ITEM_DURATION}`,
  '&:hover': {
    backgroundColor: theme.colors.highlight,
    color: theme.colors.darkAccent,
  },
  '&:active': {
    backgroundColor: theme.colors.container.tertiary,
    color: theme.colors.inactive,
  },
  '&.Mui-selected': {
    backgroundColor: theme.colors.highlight,
  },
}));

export const ListMenuItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingLeft: theme.spacing(0.5),
}));

export const HideMenuWrapper = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(6),
}));

export const HideMenuButtonText = styled(ListItemText)(() => ({
  flex: 'unset',
}));

export const MenuDrawer = styled(Drawer)(({ open }) => ({
  width: open ? KEYS.SIDEBAR_WIDTH_EXPANDED_DESKTOP : KEYS.SIDEBAR_WIDTH_COLLAPSED_DESKTOP,
  transition: `width ${KEYS.DRAWER_TRANSITION_EASING} ${KEYS.DRAWER_TRANSITION_DURATION}`,
  '& .MuiDrawer-paper': {
    transition: `width ${KEYS.DRAWER_TRANSITION_EASING} ${KEYS.DRAWER_TRANSITION_DURATION}`,
    width: open ? KEYS.SIDEBAR_WIDTH_EXPANDED_DESKTOP : KEYS.SIDEBAR_WIDTH_COLLAPSED_DESKTOP,
  },
}));

export const Main = styled.main<{ shrink: boolean }>(({ theme, shrink }) => ({
  flexGrow: 1,
  backgroundColor: theme.colors.white,
  minHeight: '100vh',
  maxWidth: `calc(100vw - ${shrink ? KEYS.SIDEBAR_WIDTH_EXPANDED_DESKTOP : KEYS.SIDEBAR_WIDTH_COLLAPSED_DESKTOP})`,
  transition: `all ${KEYS.DRAWER_TRANSITION_EASING} ${KEYS.DRAWER_TRANSITION_DURATION}`,
}));

type ChevronRightIconProps = {
  open?: boolean;
};

export const ChevronRightIcon = styled(({ open, ...other }: ChevronRightIconProps) => <ChevronRight {...other} />)(({ open, theme }) => ({
  fontSize: '16px',
  transform: open ? 'rotate(180deg)' : 'none',
  transition: `transform ${KEYS.DRAWER_TRANSITION_EASING} ${KEYS.DRAWER_TRANSITION_DURATION}`,
}));

export const FlexBox = styled.div(() => ({
  display: 'flex',
}));

type MenuIconProps = {
  component: ElementType;
};

export const MenuIcon = styled(({ component, ...other }: MenuIconProps) => {
  const IconComponent = component;
  return <IconComponent {...other} />;
})({
  flexShrink: 0,
});

export type LinkProps = RouterLinkProps & MuiLinkProps;

export const Link = styled(MuiLink)(({ theme }) => ({
  width: '100%',
  color: theme.colors.primary,
  textDecoration: 'none',
  '& .Mui-selected': {
    color: theme.colors.accent,
  },
})) as React.ComponentType<LinkProps>;
