import { OptionallyVisible } from 'components';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCrescent from 'components/icons/crescent';
import IconInfoCircled from 'components/icons/infoCircled';
import IconLight from 'components/icons/light';
import IconLock from 'components/icons/lock';
import IconRefresh from 'components/icons/refresh';
import { BarSwitch, BarSwitchOption } from 'components/inputs/switch/barSwitch';

import { AccountEmbeddableThemeMode, AccountLandingPageSettings, AccountWidgetSettings } from 'types/account';

import { KEYS, LABELS, landingPropsMap, widgetPropsMap } from './keys';
import {
  Address,
  BrowserAddressBar,
  BrowserHead,
  Container,
  LandingContainer,
  LandingTitle,
  SubTitle,
  Title,
  Widget,
  WidgetContainer,
} from './styles';

interface WidgetParams {
  apiKey: string | null;
  cryptoCurrencyCode: string;
  cryptoAmount: number;
  fiatCurrencyCode: string;
  fiatAmount: number;
  defaultCryptoCurrencyCode: string;
  defaultCryptoAmount: number;
  defaultFiatCurrencyCode: string;
  defaultFiatAmount: number;
  cryptoCurrencyCodeList: string[];
  fiatCurrencyCodeList: string[];
  networks: string;
  walletAddress: string;
  walletTag: string;
  showWalletAddressForm: boolean;
  email: string;
  enableRedirectURL: boolean;
  cryptoCurrencyCodePopularList: string[];
  lockedAmount: boolean;
  themeColor: string;
  widgetWidth: number;
  widgetHeight: number;
  hideIllustrations: boolean;
  themeRadius: number;
  themeMode: string;
}

interface WidgetPreviewProps {
  params: Partial<AccountWidgetSettings & AccountLandingPageSettings>;
  asLanding?: boolean;
  publicKey?: string;
  landingCaption?: string;
  seed: string;
}

const makeWidgetUrl = (
  params: Partial<AccountWidgetSettings & AccountLandingPageSettings & { apiKey: string }>,
  asLanding: boolean,
  seed?: string,
) => {
  const propsMap = asLanding ? landingPropsMap : widgetPropsMap;
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    const propKey = propsMap.get(key);
    const shouldIgnoreProp = !propKey || (Array.isArray(value) && isEmpty(value)) || value === null;

    if (shouldIgnoreProp) {
      return;
    }

    if (propKey === KEYS.HIDE_ILLUSTRATIONS_KEY) {
      urlParams.append(propKey, `${!value}`);
      return;
    }

    if (typeof value === 'string') {
      urlParams.append(propKey, value);
    }

    if (typeof value === 'boolean') {
      urlParams.append(propKey, `${value}`);
    }

    if (Array.isArray(value)) {
      urlParams.append(propKey, value.join(','));
    }
  });

  if (seed) {
    urlParams.append('urlSeed', seed);
  }

  if (params.apiKey) {
    urlParams.append('apiKey', params.apiKey);
  }

  if (asLanding) {
    return `${window.s4cConfig.corWidgetUrl}${params.urlSlug || ''}?${urlParams}`;
  }

  return `${window.s4cConfig.corWidgetUrl}?${urlParams}`;
};

export const WidgetPreview = ({ params, asLanding, publicKey, seed, landingCaption }: WidgetPreviewProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<AccountEmbeddableThemeMode>(AccountEmbeddableThemeMode.Light);
  const isBrowserMode = asLanding
    ? params.pageThemeMode === AccountEmbeddableThemeMode.SameAsBrowser
    : params.widgetThemeMode === AccountEmbeddableThemeMode.SameAsBrowser;

  const modeKey = asLanding ? KEYS.LANDING_MODE_KEY : KEYS.WIDGET_MODE_KEY;

  const widgetParams = {
    ...params,
    apiKey: publicKey,
    ...(isBrowserMode ? { [modeKey]: mode } : {}),
  };

  const widgetURL = makeWidgetUrl(widgetParams, Boolean(asLanding), seed);
  const landingUrl = `${window.s4cConfig.corWidgetUrl}${params.urlSlug || KEYS.SLUG_PLACEHOLDER}`;

  const handleModeChange = (option: BarSwitchOption) => {
    setMode(option.value as AccountEmbeddableThemeMode);
  };

  const widgetSize = asLanding ? KEYS.LANDING_SIZE : KEYS.WIDGET_SIZE;

  const title = asLanding ? t(LABELS.LANDING_TITLE) : t(LABELS.WIDGET_TITLE);

  const themeModeOptions: BarSwitchOption[] = [
    {
      value: AccountEmbeddableThemeMode.Light,
      icon: <IconLight />,
      label: t(LABELS.MODES[AccountEmbeddableThemeMode.Light]),
    },
    {
      value: AccountEmbeddableThemeMode.Dark,
      icon: <IconCrescent />,
      label: t(LABELS.MODES[AccountEmbeddableThemeMode.Dark]),
    },
  ];

  if (asLanding) {
    return (
      <Container>
        <LandingTitle>
          <Title>{title}</Title>
          <SubTitle>
            <IconInfoCircled />
            {t(LABELS.LANDING_CAPTION)}
          </SubTitle>
        </LandingTitle>
        <LandingContainer>
          <BrowserHead>
            <BrowserAddressBar>
              <Address>
                <IconLock />
                {landingUrl}
              </Address>
              <IconRefresh />
            </BrowserAddressBar>
          </BrowserHead>
          <Widget src={widgetURL} {...widgetSize} />
        </LandingContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      <OptionallyVisible visible={isBrowserMode}>
        <BarSwitch value={mode} onChange={handleModeChange} options={themeModeOptions} />
      </OptionallyVisible>
      <WidgetContainer>
        <Widget src={widgetURL} {...widgetSize} />
      </WidgetContainer>
    </Container>
  );
};
