import { PageWrapper } from 'components';
import { useNotifications } from 'providers/notifications/useNotifications';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useGetCountriesListQuery } from 'services/assets';
import {
  useChangeUserStatusMutation,
  useFetchKYCStatusMutation,
  useGetCustomerQuery,
  useIncreaseKYCAttemptsMutation,
} from 'services/customers';

import LoadingIndicator from 'components/loadingIndicator/loadingIndicator';

import RoutePath from 'router/path';

import { selectCustomer } from 'state/slices/customerSlice';
import { selectUserLevel } from 'state/slices/userSlice';

import { CustomerKYCStatus, CustomerStatus, CustomerTerminationReason } from 'types/customer';
import { APIError } from 'types/error';
import { NotificationSeverity } from 'types/notifications';
import { UserAccessLevel } from 'types/user';

import { CustomerBalancesListContainer } from './components/balances/container';
import { CustomerOrderListContainer } from './components/orderList/container';
import { CustomerView } from './customerView';
import { LABELS } from './keys';

export const CustomerViewContainer = () => {
  const match = useMatch(RoutePath.Ramp.Customers.ById);
  const { t } = useTranslation();
  const { pushToast, pushDialog } = useNotifications();
  const { uuid } = match?.params || {};
  const customer = useSelector(selectCustomer);
  const userLevel = useSelector(selectUserLevel);
  const isAdmin = Boolean(userLevel && userLevel >= UserAccessLevel.Admin);
  const { isLoading } = useGetCustomerQuery(uuid!, {
    skip: !uuid,
  });

  const [fetchKYCStatus, { isLoading: isStatusUpdating }] = useFetchKYCStatusMutation();
  const [increaseKYCAttempts, { isLoading: isUpdatingRetryCount }] = useIncreaseKYCAttemptsMutation();
  const [updateUserStatus, { isLoading: isUpdatindStatus }] = useChangeUserStatusMutation();

  const { data: countries = [] } = useGetCountriesListQuery();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (!customer) {
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator padded />;
  }

  const updateKYCStatus = async () => {
    const response = await fetchKYCStatus(customer.uuid);
    if ('error' in response) {
      pushToast({
        severity: NotificationSeverity.error,
        message: (response.error as APIError)?.errorMessage,
      });
    }
  };

  const updateStatus = async (nextStatus: CustomerStatus, options?: { reasonCode: CustomerTerminationReason; notes?: string }) => {
    const response = await updateUserStatus({ clientUuid: customer.uuid, status: nextStatus, ...(options || {}) });
    const hasError = 'error' in response;
    if (hasError) {
      pushDialog({
        severity: NotificationSeverity.error,
        title: t(LABELS.CHANGE_STATUS.ERROR_TITLE),
        dialogTitle: t(LABELS.CHANGE_STATUS.ERROR_PANEL_TITLE),
        message: (response.error as APIError).errorMessage || t(LABELS.CHANGE_STATUS.ERROR_MESSAGE),
      });

      return false;
    }

    return true;
  };

  const allowKYCRetry = async () => {
    const response = await increaseKYCAttempts(customer.uuid);
    const hasError = 'error' in response;
    if (hasError) {
      pushDialog({
        severity: NotificationSeverity.error,
        title: t(LABELS.RETRY_KYC.ERROR_TITLE),
        dialogTitle: t(LABELS.RETRY_KYC.ERROR_PANEL_TITLE),
        message: (response.error as APIError).errorMessage || t(LABELS.RETRY_KYC.ERROR_MESSAGE),
      });
    }
  };

  const canChangeStatus = Boolean(customer.zHashParticipantCode) && customer.status !== CustomerStatus.Terminated;
  const isTerminationAvailable =
    isAdmin && customer.status !== CustomerStatus.Terminated && customer.kycStatus === CustomerKYCStatus.Approved;

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <CustomerView
        kycProps={{ updateKYCStatus, kycStatus: customer.kycStatus!, isUpdating: isStatusUpdating, allowKYCRetry, isUpdatingRetryCount }}
        customer={customer}
        statusProps={{
          isTerminationAvailable,
          updateStatus,
          canChangeStatus,
          isUpdating: isUpdatindStatus,
        }}
        countries={countries}
      />
      <CustomerOrderListContainer customer={customer} />
      <CustomerBalancesListContainer customer={customer} />
    </PageWrapper>
  );
};
