import { DateFilterValue } from 'types/filters';
import { RequestPagination, ServerPagination } from 'types/pagination';
import { RequestSorting } from 'types/sort';

export interface MerchantsListQuery {
  filters?: {
    uuid?: string | string[];
    date?: DateFilterValue;
    status?: string;
  };
  search?: string;
  pagination?: RequestPagination;
  sort?: RequestSorting;
}

export enum MerchantStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deactivated = 'deactivated',
}

export interface MerchantInfo {
  uuid: string;
  name: string;
  publicKey: string;
  createdAt: string;
  bridgeCustomerId: string;
  mainBankAccountUuid: string | null;
  status: MerchantStatus;
  shift4MerchantId: string | null;
  ownerEmail: string;
  ownerFullName: string;
  lightModeLogoUrl: string | null;
  darkModeLogoUrl: string | null;
  processingFee: number;
  webhookUrl: string | null;
  title: string;
}

export type MerchantsListResponse = { data: MerchantInfo[]; pagination: ServerPagination };
