import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import HTTPMethod, { Response } from 'types/http';
import { UserRecord } from 'types/user';

import { getDefaultPagination } from 'utils/pagination';

import { AccountsListQuery, AccountsListResponse } from '../accounts/types';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import {
  ChangePasswordRequestBody,
  ChangePasswordResponse,
  ProfileInfoResponse,
  ProfileUpdateRequestBody,
  ProfileUpdateResponse,
  RevokeAccessToAccountRequestBody,
  RevokeAccessToAccountResponse,
} from './types';

const REDUCER_PATH = 'profileApi';

export const profileApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.PROFILE_ACCOUNT_LIST],
  endpoints: (builder) => ({
    getAccountsList: builder.query<AccountsListResponse, AccountsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Accounts.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      providesTags: [TAGS.PROFILE_ACCOUNT_LIST],
      transformResponse: (response: Response<AccountsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
    }),
    getProfile: builder.query<UserRecord, void>({
      query: () => ({
        url: Endpoint.Profile.Me,
        method: HTTPMethod.GET,
      }),
      transformResponse: (response: ProfileInfoResponse) => {
        return response.data!;
      },
    }),
    updateProfile: builder.mutation<ProfileUpdateResponse, ProfileUpdateRequestBody>({
      query: (requestBody) => ({
        url: Endpoint.Profile.Me,
        method: HTTPMethod.PUT,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.PROFILE_ACCOUNT_LIST]),
    }),
    revokeAccessToAccount: builder.mutation<RevokeAccessToAccountResponse, RevokeAccessToAccountRequestBody>({
      query: (requestBody) => ({
        url: Endpoint.Users.RevokeAccess,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.PROFILE_ACCOUNT_LIST]),
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequestBody>({
      query: (requestBody) => ({
        url: Endpoint.Users.ChangePassword,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useGetAccountsListQuery,
  useRevokeAccessToAccountMutation,
} = profileApi;
export default profileApi;
