import { useAuth0 } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAvailableAccountsQuery } from 'services/users';

import RoutePath from 'router/path';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { setAccount } from 'state/slices/accountSlice';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountInfo, AccountType } from 'types/account';
import { UserAccessLevel } from 'types/user';

import { Breadcrumbs } from '../../breadcrumbs';
import { BreadcrumbsItem } from '../../breadcrumbs/types';
import OptionallyVisible from '../../optionallyVisible';
import { Typography } from '../../typography';
import { CreateAccountModal } from '../createAccount';
import { useCreateAccountModal } from '../createAccount/useCreateAccountModal';
import { ProfileMenu } from '../profileMenu';
import {
  ContentWrapper,
  Header,
  ProfileMenuContainer,
  TestEnvironmentBadge,
  TestEnvironmentIndicator,
  Wrapper,
} from './pageWrapper.styles';

export interface PageWrapperProps {
  pageTitle?: string;
  breadcrumbs: BreadcrumbsItem[];
}

const TEST_MODE_LABEL = ['app.testMode', 'Test mode'];
const isTestMode = Boolean(window.s4cConfig.isTestEnvironment);

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({ children, pageTitle, breadcrumbs }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { logout } = useAuth0();
  const { account } = useSelector(selectAccount);
  const { data: availableAccountsData } = useGetAvailableAccountsQuery({});
  const dispatch = useDispatch();
  const userLevel = useSelector(selectUserLevel);

  const isPartner = userLevel === UserAccessLevel.Partner;
  const selectedAccount = isPartner ? account : undefined;
  const availableAccounts = isPartner ? availableAccountsData?.data?.map((record) => record.account) || [] : [];
  const rampAvailableAccounts = availableAccounts.filter((account) => account.type === AccountType.Ramp);
  const pwcAvailableAccounts = availableAccounts.filter((account) => account.type === AccountType.Pwc);
  const isAccountCreationAllowed = userLevel === UserAccessLevel.SuperAdmin || userLevel === UserAccessLevel.Admin;

  const { handleCreateAccountModalOpen, createdAccount, modalProps: createAccountModalProps } = useCreateAccountModal();

  const handleCreateAccount = () => {
    if (isAccountCreationAllowed) {
      handleCreateAccountModalOpen();
    }
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleAccountSwitch = (nextAccount: AccountInfo) => {
    const { account, access } = availableAccountsData?.data?.find((record) => record.account.uuid === nextAccount.uuid) || {};
    if (account && access) {
      dispatch(setAccount({ account, access }));
    }
  };

  const navigateToProfile = () => {
    navigate(RoutePath.Profile.Root);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Header>
          <Breadcrumbs items={breadcrumbs} />
          <ProfileMenuContainer>
            <OptionallyVisible visible={isTestMode}>
              <TestEnvironmentBadge>{t(TEST_MODE_LABEL)}</TestEnvironmentBadge>
            </OptionallyVisible>
            <ProfileMenu
              logout={handleLogout}
              selectedAccount={selectedAccount}
              rampAccounts={availableAccounts}
              rampAvailableAccounts={rampAvailableAccounts}
              pwcAvailableAccounts={pwcAvailableAccounts}
              navigateToProfile={navigateToProfile}
              onAccountSwitch={handleAccountSwitch}
              createAccount={handleCreateAccount}
              isAccountCreationAllowed={isAccountCreationAllowed}
            />
          </ProfileMenuContainer>
        </Header>
        <OptionallyVisible visible={isTestMode}>
          <TestEnvironmentIndicator />
        </OptionallyVisible>
        <OptionallyVisible visible={Boolean(pageTitle)}>
          <Typography variant="caption">{pageTitle}</Typography>
        </OptionallyVisible>
        {children}
      </ContentWrapper>
      <OptionallyVisible visible={Boolean(isAccountCreationAllowed && createdAccount)}>
        <CreateAccountModal {...createAccountModalProps} />
      </OptionallyVisible>
    </Wrapper>
  );
};

export default PageWrapper;
