import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import HTTPMethod, { Response } from 'types/http';

import { getDefaultPagination } from '../../utils/pagination';
import { AccountsListQuery, AccountsListResponse, AvailableAccountsResponse } from '../accounts/types';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import {
  AcceptInviteResponse,
  CreateUserPayload,
  CreateUserResponse,
  UpdateUserPayload,
  UpdateUserResponse,
  UsersListQuery,
  UsersListResponse,
} from './types';

const REDUCER_PATH = 'usersApi';

export const usersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.USER_ACCOUNT_LIST, TAGS.USER_LIST],
  endpoints: (builder) => ({
    getAccountsList: builder.query<AccountsListResponse, AccountsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Accounts.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<AccountsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.USER_ACCOUNT_LIST],
    }),
    getAvailableAccounts: builder.query<AvailableAccountsResponse, {}>({
      query: () => ({
        url: Endpoint.Accounts.Available,
        method: HTTPMethod.GET,
      }),
      transformResponse: (response: Response<AvailableAccountsResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
    }),
    getUsersList: builder.query<UsersListResponse, UsersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Users.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<{ users: UsersListResponse }>) => {
        if (!response?.data) {
          return [];
        }

        return response.data.users;
      },
      providesTags: [TAGS.USER_LIST],
    }),
    acceptInvite: builder.mutation<AcceptInviteResponse, string>({
      query: (inviteKey) => ({
        url: Endpoint.Users.AcceptInvite(inviteKey),
        method: HTTPMethod.POST,
      }),
      invalidatesTags: [TAGS.USER_ACCOUNT_LIST],
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserPayload>({
      query: (requestBody) => ({
        url: Endpoint.Users.Create,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: [TAGS.USER_LIST],
    }),
    editUser: builder.mutation<UpdateUserResponse, UpdateUserPayload>({
      query: ({ uuid, ...requestBody }) => ({
        url: Endpoint.Users.ById(uuid),
        method: HTTPMethod.PUT,
        body: requestBody,
      }),
      invalidatesTags: [TAGS.USER_LIST],
    }),
  }),
});

export const {
  useAcceptInviteMutation,
  useGetAccountsListQuery,
  useGetAvailableAccountsQuery,
  useGetUsersListQuery,
  useCreateUserMutation,
  useEditUserMutation,
} = usersApi;
export default usersApi;
