/* eslint-disable react/no-array-index-key */
import { Typography } from 'components';
import React, { useState } from 'react';
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom';

import LogoCollapsed from 'assets/images/logoCollapsed.svg';
import LogoExpanded from 'assets/images/logoExpanded.svg';

import OptionallyVisible from 'components/optionallyVisible';

import { LayoutMenuItem, LayoutWithSidebarProps } from '../types';
import {
  ChevronRightIcon,
  CollapsedLogoImg,
  DrawerContent,
  ExpandedLogoImg,
  FlexBox,
  HideMenuButtonText,
  HideMenuWrapper,
  Link,
  ListMenu,
  ListMenuItem,
  ListMenuItemButton,
  ListMenuItemText,
  ListMenuSubheader,
  LogoWrapper,
  Main,
  MenuDrawer,
  MenuIcon,
} from './layoutWithSidebar.desktop.styles';

const renderMenuItem = (
  { label, icon: Icon, to, openInNewWindow, subheader }: LayoutMenuItem,
  index: number,
  open: boolean,
  pathname: string,
) => {
  if (subheader) {
    return (
      <ListMenuSubheader first={index === 0} open={open}>
        {subheader}
      </ListMenuSubheader>
    );
  }

  return (
    <ListMenuItem key={index}>
      <Link to={to!} component={RouterLink} target={openInNewWindow ? '_blank' : ''}>
        <ListMenuItemButton selected={pathname === to}>
          <MenuIcon component={Icon!} />
          <OptionallyVisible visible={open}>
            <ListMenuItemText primary={<Typography variant="subtitle1">{label}</Typography>} />
          </OptionallyVisible>
        </ListMenuItemButton>
      </Link>
    </ListMenuItem>
  );
};

export const LayoutWithSidebarDesktop: React.FC<LayoutWithSidebarProps> = ({ layoutMenuItems }) => {
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const toggleDrawer = () => setOpen(!open);

  const drawerContent = (
    <DrawerContent>
      <LogoWrapper>
        <ExpandedLogoImg open={open} src={LogoExpanded} alt="Shift4 Logo" width="161" height="18" />
        <CollapsedLogoImg open={open} src={LogoCollapsed} alt="Shift4 Logo" width="48" height="55" />
      </LogoWrapper>
      <ListMenu>
        {layoutMenuItems?.map((item, index) => renderMenuItem(item, index, open, pathname))}
        <HideMenuWrapper>
          <ListMenuItemButton onClick={toggleDrawer}>
            <ChevronRightIcon open={open} />
            <OptionallyVisible visible={open}>
              <HideMenuButtonText primary={<Typography variant="subtitle1">Hide</Typography>} />
            </OptionallyVisible>
          </ListMenuItemButton>
        </HideMenuWrapper>
      </ListMenu>
    </DrawerContent>
  );

  return (
    <FlexBox>
      <MenuDrawer variant="permanent" open={open}>
        {drawerContent}
      </MenuDrawer>
      <Main shrink={open}>
        <Outlet />
      </Main>
    </FlexBox>
  );
};

export default LayoutWithSidebarDesktop;
