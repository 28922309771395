import IconBank from 'components/icons/bank';
import IconBook from 'components/icons/book';
import IconCash from 'components/icons/cash';
import IconMarketplace from 'components/icons/marketplace';
import IconPeople from 'components/icons/people';
import IconReport from 'components/icons/report';
import IconSettings from 'components/icons/settings';

import i18next from 'locales/i18next';

import RoutePath from 'router/path';

import { MenuItem } from 'types/routing';

const { t } = i18next;

export const menuItems: MenuItem[] = [
  {
    subheader: t('Ramp'),
  },
  {
    label: t('Orders'),
    icon: IconReport,
    to: RoutePath.Ramp.Orders.Root,
  },
  {
    label: t('Customers'),
    icon: IconPeople,
    to: RoutePath.Ramp.Customers.Root,
  },
  {
    label: t('Partner Accounts'),
    icon: IconBank,
    to: RoutePath.Ramp.Accounts.List,
  },
  {
    subheader: t('Pay with crypto'),
  },
  {
    label: t('Orders'),
    icon: IconCash,
    to: RoutePath.Pwc.Orders.Root,
  },
  {
    label: t('Customers'),
    icon: IconPeople,
    to: RoutePath.Pwc.Customers.Root,
  },
  {
    label: t('Merchant Accounts'),
    icon: IconMarketplace,
    to: RoutePath.Pwc.Accounts.List,
  },
  {
    subheader: t('General'),
  },
  {
    label: t('General Settings'),
    icon: IconSettings,
    to: RoutePath.GeneralSettings.Root,
  },
  {
    label: t('Documentation'),
    icon: IconBook,
    openInNewWindow: true,
    to: window?.s4cConfig?.documentationUrl || '',
  },
];

export default menuItems;
