import { DocumentationRoute } from 'pages/documentation/route';
import { NotImplementedRoute } from 'pages/notImplemented/route';
import { OrderListRoute, OrderViewRoute } from 'pages/orders/route';
import { ProfileRoute } from 'pages/profile/route';

import { AccountType } from 'types/account';
import { GetDefaultLoginRedirectRoute, RouteSetup } from 'types/routing';

import RoutePath from '../../../path';
import { menuItems } from './menuItems';

export const PartnerViewerRouterSetup = (
  hasAccounts: boolean,
  accountType: AccountType,
  getDefaultLoginRedirectRoute: GetDefaultLoginRedirectRoute,
): RouteSetup => ({
  availableRoutes: {
    [AccountType.Ramp]: [
      {
        path: RoutePath.Ramp.Orders.Root,
        component: OrderListRoute,
      },
      {
        path: RoutePath.Ramp.Orders.ById,
        component: OrderViewRoute,
      },
      {
        path: RoutePath.Documentation.Root,
        component: DocumentationRoute,
      },
      {
        path: RoutePath.Profile.Root,
        component: ProfileRoute,
      },
    ],
    [AccountType.Pwc]: [
      {
        path: RoutePath.Pwc.Orders.Root,
        component: NotImplementedRoute,
      },
      {
        path: RoutePath.Pwc.Orders.ById,
        component: NotImplementedRoute,
      },
      {
        path: RoutePath.Pwc.Orders.ReceiptById,
        component: NotImplementedRoute,
      },
      {
        path: RoutePath.Documentation.Root,
        component: DocumentationRoute,
      },
      {
        path: RoutePath.Profile.Root,
        component: ProfileRoute,
      },
    ],
  }[accountType],
  menuItems: menuItems[accountType],
  defaultLoginRedirectRoute: getDefaultLoginRedirectRoute(hasAccounts, accountType),
});

export default PartnerViewerRouterSetup;
