import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { CopyMenuItem, MenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';

import { LABELS } from '../../keys';
import { MerchantDataRow } from '../../types';

export const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { uuid } = row as MerchantDataRow;

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <MenuItem>{t(LABELS.ACTIONS.OPEN_DETAILS)}</MenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_ACCOUNT_ID)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};
