import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from '../../utils/pagination';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import { MerchantsListQuery, MerchantsListResponse } from './types';

const REDUCER_PATH = 'merchantsApi';

export const merchantsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.MERCHANT_LIST],
  endpoints: (builder) => ({
    getMerchantsList: builder.query<MerchantsListResponse, MerchantsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Merchants.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<MerchantsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.MERCHANT_LIST],
    }),
  }),
});

export const { getMerchantsList } = merchantsApi.endpoints;
export const { useGetMerchantsListQuery, useLazyGetMerchantsListQuery } = merchantsApi;
export default merchantsApi;
